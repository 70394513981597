import { RefObject, useEffect } from 'react';

/**
 * Custom React hook that locks or unlocks body scroll based on the provided condition.
 * It also handles touch events on mobile devices to prevent scrolling when the body scroll is locked.
 *
 * @param lockCondition - A boolean that determines whether the body scroll should be locked.
 *  `true` to lock scroll, `false` to unlock.
 * @param el - An optional ref to an element that should remain scrollable, e.g. a modal's content.
 */
export default function useLockBodyScroll(lockCondition: boolean, el?: RefObject<HTMLElement>) {
  useEffect(() => {
    if (lockCondition) {
      document.body.classList.add('no-scroll');
      document.body.style.overflow = 'hidden';
    } else {
      document.body.classList.remove('no-scroll');
      document.body.style.overflow = '';
    }

    // Optionally handle mobile touch move events
    const preventTouchMove = (e: TouchEvent) => {
      if (e.target instanceof Node && el?.current?.contains(e.target)) {
        return;
      }

      e.preventDefault();
    };

    if (lockCondition) {
      document.body.addEventListener('touchmove', preventTouchMove, { passive: false });
    }

    return () => {
      document.body.style.overflow = 'initial';
      document.body.removeEventListener('touchmove', preventTouchMove);
    };
  }, [lockCondition]);
}
